<template>
  <div class="goodsList">
    <div v-for="(item, index) in list" :key="index">
      <goodsV2
        class="box-m-t-20"
        :img_url="item.cover"
        :description="item.title"
        :num="item.total"
        :unit_price="item.goods_amount"
        :total_price="item.goods_amount"
      >
        <button v-if="buttonShow" class="btn">申请退款</button>
      </goodsV2>
    </div>
    <div v-if="goodsList.length > 3" class="box-m-t-20">
      <div class="foot" @click="isMore = !isMore">
        <img
          :src="require('@STATIC/image/icons/arrowDownFill.png')"
          :class="{ 'foot-rotate': !isMore }"
        />
        <span class="box-m-l-4">{{ isMore ? "展开更多" : "收起" }}</span>
      </div>
    </div>
    <div
      style="width: 100%; height: 1px; background-color: #ebebeb"
      class="box-m-t-13"
    ></div>
    <cellV2
      class="box-m-t-18"
      :type="3"
      leftText="商品总额"
      :rightText="orderInfo.goods_amount"
    ></cellV2>
    <cellV2
      class="box-m-t-18"
      :type="3"
      leftText="人生券抵扣"
      :rightText="orderInfo.deduct_amount"
    ></cellV2>
    <cellV2
      class="box-m-t-18"
      :type="4"
      leftText="实付费"
      :rightText="orderInfo.order_amount"
    ></cellV2>
  </div>
</template>

<script>
import goodsV2 from "@T/community/goods/goodsV2";
import cellV2 from "@T/community/cell/cellV2.vue";
export default {
  name: "StoresGoodsListV1",
  components: {
    goodsV2,
    cellV2,
  },
  props: {
    goodsList: {
      type: Array,
      default() {
        return [];
      },
    },
    buttonShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    orderInfo: {
      type: Object,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isMore: true,
    };
  },
  computed: {
    list() {
      if (this.isMore) {
        return this.$props.goodsList.slice(0, 3);
      } else {
        return this.$props.goodsList;
      }
    },
  },
  mounted() {
    // this.buttonShow=this.$props.refundButtonShow
    // this.orderInfo =this.$props.orderInfo
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@include b(goodsList) {
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}
@include b(foot) {
  transition: 0s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 10px;
    height: 10px;
  }
  & > span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939393;
  }
  @include m(rotate) {
    transform: rotate(180deg);
  }
}
@include b(btn) {
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  border: 1px solid #939393;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  background: transparent;
}
</style>
