<template>
  <div class="express-info">
    <div class="order-step">
      <img :src="timeLock" />
      {{ title }}
    </div>
    <div class="express-list-container">
      <div class="express-list" v-for="(item, index) in list" :key="index">
        <div class="express-list-line">
          <div class="line" v-if="index != list.length - 1"></div>
          <div class="dot-container">
            <div class="dot" v-if="index > 0"></div>
            <div class="checked" v-else>
              <img :src="checked" />
            </div>
          </div>
        </div>
        <div class="express-list-info">
          <div :class="[index == 0 ? 'active' : '']" class="message">
            {{ item.title }}
          </div>
          <div class="timestamp">{{ item.time }}</div>
        </div>
      </div>
    </div>
    <div v-if="dataList.length > 3">
      <div class="foot" @click="isMore = !isMore">
        <img
          :src="require('@STATIC/image/icons/arrowDownFill.png')"
          :class="{ 'foot-rotate': !isMore }"
        />
        <span class="box-m-l-4">{{ isMore ? "展开更多" : "收起" }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import timeLock from "@STATIC/image/icons/timeLock.png";
import checked from "@STATIC/image/icons/checked.png";

export default {
  name: "expressStoresInfoV1",
  props: {
    stepInfo: {
      type: Array,
      default() {
        return [];
      },
    },
    title:{
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      isMore: true,
      timeLock,
      checked,
      dataList: [
      ],
    };
  },
  created() {
      this.dataList=this.$props.stepInfo

  },
  computed: {

    list() {
      this.dataList=this.$props.stepInfo

      if (this.isMore) {
        return this.dataList.slice(0, 3);
      } else {
        return this.dataList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@include b(foot) {
  transition: 0s ease all;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 10px;
    height: 10px;
  }
  & > span {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #939393;
  }
  @include m(rotate) {
    transform: rotate(180deg);
  }
}
.express-info {
  width: 100%;
  box-sizing: border-box;
  padding: 18px 23px;
  background-color: #fff;
  border-radius: 10px;
  .order-step {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .express-list-container {
    margin-top:20px;
    padding: 0 22px;
    font-size: 14px;

    .express-list {
      width: 100%;
      display: flex;

      .express-list-line {
        width: 30px;
        display: flex;
        position: relative;

        .line {
          width: 1px;
          height: 100%;
          border-left: #d1d1d1 dashed 1px;
          margin-left: 11px;
          position: absolute;
          left: 0;
          top: 5px;
        }

        .dot-container {
          width: 16px;
          height: 16px;
          position: absolute;
          left: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          .dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            overflow: hidden;
            background: #d1d1d1;
          }

          .checked {
            width: 16px;
            height: 16px;
            background: #ffffff;

            img {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .express-list-info {
        flex: 1;
        color: #c0c0c0;

        .message {
          margin-bottom: 8px;
        }

        .active {
          color: #f46a17;
        }

        .timestamp {
          margin-bottom: 32px;
        }
      }
    }
  }
}
</style>
